@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/SpaceGrotesk-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/SpaceGrotesk-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/SpaceGrotesk-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/SpaceGrotesk-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/SpaceGrotesk-Bold.woff2') format('woff2');
}

:root {
  /* #region  /**=========== Primary Color =========== */
  /* !STARTERCONF Customize these variable, copy and paste from /styles/colors.css for list of colors */
  --tw-color-primary-50: 251 249 235;
  --tw-color-primary-100: 247 240 202;
  --tw-color-primary-200: 241 224 151;
  --tw-color-primary-300: 232 201 92;
  --tw-color-primary-400: 226 182 62;
  --tw-color-primary-500: 208 154 34;
  --tw-color-primary-600: 179 120 27;
  --tw-color-primary-700: 143 87 25;
  --tw-color-primary-800: 119 70 28;
  --tw-color-primary-900: 102 59 29;
  --color-primary-50: rgb(var(--tw-color-primary-50)); /* #f0f9ff */
  --color-primary-100: rgb(var(--tw-color-primary-100)); /* #e0f2fe */
  --color-primary-200: rgb(var(--tw-color-primary-200)); /* #bae6fd */
  --color-primary-300: rgb(var(--tw-color-primary-300)); /* #7dd3fc */
  --color-primary-400: rgb(var(--tw-color-primary-400)); /* #38bdf8 */
  --color-primary-500: rgb(var(--tw-color-primary-500)); /* #0ea5e9 */
  --color-primary-600: rgb(var(--tw-color-primary-600)); /* #0284c7 */
  --color-primary-700: rgb(var(--tw-color-primary-700)); /* #0369a1 */
  --color-primary-800: rgb(var(--tw-color-primary-800)); /* #075985 */
  --color-primary-900: rgb(var(--tw-color-primary-900)); /* #0c4a6e */
  /* #endregion  /**======== Primary Color =========== */
}

@layer base {
  html {
    scroll-behavior: smooth;
  }

  html,
  body {
    @apply dark:bg-trueGray-800;
  }

  /* #region  /**=========== Typography =========== */
  .h0 {
    @apply font-primary text-3xl font-bold md:text-5xl;
  }

  h1,
  .h1 {
    @apply font-primary text-2xl font-bold md:text-4xl;
  }

  h2,
  .h2 {
    @apply font-primary text-xl font-bold md:text-3xl;
  }

  h3,
  .h3 {
    @apply font-primary text-lg font-bold md:text-2xl;
  }

  h4,
  .h4 {
    @apply font-primary text-base font-bold md:text-lg;
  }

  body,
  .p {
    @apply font-primary text-sm md:text-base;
  }
  /* #endregion  /**======== Typography =========== */

  .layout {
    /* 1100px */
    max-width: 68.75rem;
    @apply mx-auto w-11/12;
  }

  .bg-dark a.custom-link {
    @apply border-gray-200 hover:border-gray-200/0;
  }

  /* Class to adjust with sticky footer */
  .min-h-main {
    @apply min-h-[calc(100vh-56px)];
  }
}
